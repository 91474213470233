.footer{
    background-color: var(--background);
    padding: 5rem 0;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100% );
}

.footer .container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
    padding: 1rem;
}

.footer .col{
    display: flex;
    flex-direction: column;
}

.footer .bar{
    width: 30px;
    border-bottom: 4px solid var(--primary);
    margin: 1rem 0;
}

.footer a{
    font-size: 1rem;
}

.footer .icon {
    color: #303030;
    margin-right: 4px;
}

.footer h5{
    margin-top: 2rem;
}

@media screen and (max-width: 940px){
    .footer .container{
        grid-template-columns: repeat(2,1fr);
    }

    .footer .col-1{
        display: none;
    }

 

}