.header {
    width: 100%;
    height: 90px;
    border-bottom: 1px  lightslategrey;
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: rgb(0 0 0 / 4%) 0px 8px 24px;
}

.header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1rem;
}

.colors {
    color: var(--primary);
}

.nav-menu{
    display: flex; /**lays things down horizontally**/

}

.nav-menu  li {
   padding: 0 1rem;
}

.nav-menu a {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
}

.hamburger {
    display: none;
    cursor: pointer;
}



/***if display is desktop screen***/
@media screen and (max-width: 1240px) {
   .hamburger
        {
display: flex;
        }

        .nav-menu{
            position: absolute;
            flex-direction: column; /***make the items a column**/
            justify-content: start;
            top: 90px;
            right: -100%;/**off the screen a 100%*/
            width: 50%;
            height: 100vh;
            transition: 0.4s;
            z-index: 2;
            background: #fff;
            border-left: 1px solid #eee;
        }

        .nav-menu li{
            padding: 1rem;
            width: 100%;
            border-bottom: 1px solid #eee;

        }

        .nav-menu.active{
            right: 0;
        }
        .nav-menu a{
            font-size: 1.2rem;
        }
    }